import * as React from "react";
import { DataObserveKey, VisualComponent } from "../../../types/fancy-base-attributes";
import { cn } from "../../../utils/shorthands";
import * as scss from "./list.scss";

type ListProps = {
	/** Items displayed in the list */
	items: React.ReactNode[];
} & DataObserveKey &
	VisualComponent;

export function Ul(props: ListProps): JSX.Element {
	const { items, className, style } = props;
	return (
		<ul
			data-observe-key={props["data-observe-key"]}
			data-component="ul"
			role="list"
			className={cn(scss.ul, className)}
			style={style}
		>
			{inner(items)}
		</ul>
	);
}

export function Ol(props: ListProps): JSX.Element {
	const { items, className, style } = props;
	return (
		<ol
			data-observe-key={props["data-observe-key"]}
			data-component="ol"
			role="list"
			className={cn(scss.ol, className)}
			style={style}
		>
			{inner(items)}
		</ol>
	);
}

function inner(items: React.ReactNode[]): JSX.Element[] {
	return items.map((x, idx) => (
		<li className={scss.li} key={idx}>
			{x}
		</li>
	));
}
